import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import siteConfig from '../../data/siteConfig'
import { withPrefix } from "gatsby"
import loadable from '@loadable/component'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import { hireMe } from '../../data/myHire'

const Layout = loadable(() => import('../components/layout'))

const Hire = ({ className, location }) => {
  const title = "Hire Me"
  const { keywords, hire } = siteConfig
  return (
    <Layout location={location}>
      <SEO
        title={title}
        keywords={keywords}
      />

      <Hero
        heroImg={withPrefix('/images/mountains3.jpg')}
        title={title}
      />

      <Wrapper className={className}>
        <Container className="page-content" fluid>
          <Row>
						<Col xs={4} className='hire-me'>
							<img
								className='hire__image'
								src={withPrefix(hire.image)}
								alt="office"
							/>
							<div>
								<h1 className='hire__text'>Hire Me</h1>
								<h3 className='hire__text'>{hire.summary}</h3>
								<p dangerouslySetInnerHTML={{__html: hireMe }}></p>
							</div>
						</Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default styled(Hire)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .hire-me {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
		text-align: center;
  }
  .hire__image {
    box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.75);
    max-width: 600px;
    margin: 0 auto 64px;
		width: 100%;
		height: 100%;
  }
	.hire__text {
		text-transform: uppercase;
	}
`
