module.exports = {
  hireMe: `<p>Hello, I'm Corey Waldrop, an American software engineer and Navy veteran with a deep passion for programming, web development, and modern technology.</p>
<p>After graduating college in 2008, I joined the U.S. Navy with the desire to see the world and explore new challenges. It was during my service that I discovered my love for computer science and programming. Since then, I’ve pursued a career in software development, focusing heavily on web technologies, and have never stopped learning or adapting to the latest industry trends.</p>
<p>Over the years, I’ve developed a strong focus on web-first development, utilizing modern paradigms like typed JavaScript (TypeScript), reactive functional programming, event-driven architectures, and AI. The rapid evolution of technologies like Node.js, React, Angular, RESTful APIs, GraphQL, Kafka, and OpenAI has not only fueled my passion but given me the tools to deliver cutting-edge solutions to my clients.</p>
<p>I’ve worked with a range of companies and clients—both full-time and as a freelancer—developing everything from complex microservices and REST APIs to sleek front-end applications. Alongside this, I’ve pursued entrepreneurial ventures, managing businesses while maintaining a hands-on role in development. Recently, as a new husband and father, I’ve decided to shift towards finding a full-time position to provide more stability for my family, while continuing to do what I love—building, innovating, and creating impactful software.</p>
<h4>Professional Background:</h4>
<p>I’m a Navy veteran with nearly 10 years of experience in both service and managerial roles within the private sector. My technical expertise spans multiple platforms and languages, including Java, TypeScript, Python, and PHP, with hands-on experience in cloud computing, microservice architecture, and API development. I'm skilled in rapidly adapting to different programming paradigms, whether it’s compiled or scripted languages, native or cloud-based solutions, continuous integration, or legacy systems maintenance.</p>
<p>While modern web development is my preferred domain, my capabilities also extend to microservice infrastructure, CI/CD pipelines, DevOps, and software design from the ground up. I am well-versed in tools and frameworks like AWS, Git, Kafka, Docker, Linux, Terraform, GraphQL, OAuth, SQL/NoSQL, and more.</p>
<p>Beyond software engineering, I’ve held leadership roles, managing teams and running successful businesses. I’m an effective communicator, quick thinker, and have demonstrated salesmanship and creativity. However, what I value most is humility and the continuous pursuit of new knowledge—a mindset that’s essential in the ever-evolving tech industry.</p>
<h4>Consulting and Freelancing:</h4>
<p>Since transitioning to the private sector, I’ve consistently taken on freelance work, collaborating with clients to develop web applications, maintain systems, and solve complex problems. Whether serving as a consultant or a hands-on developer, I enjoy working closely with clients to bring their visions to life. Even as I pursue full-time opportunities, I remain open to freelancing, offering my expertise in web, mobile, desktop, or embedded applications.</p>
<h4>What I Offer:</h4>
<p>Here are a few of the key services I provide...</p>
<p>- <strong>Full-stack MVP Development:</strong> Do you have a product idea but can't execute it yourself? I can build your product idea from scratch, delivering a robust, full-stack application that solves your most complex challenges.</p>
<p>- <strong>Web Technology Migration:</strong> Is your company considering a migration to modern web technologies but no one is there for a proper on-boarding? I specialize in modernizing legacy systems, transitioning businesses to cloud-based infrastructures, and implementing the latest security protocols.</p>
<p>- <strong>API Development and UI Integration:</strong> Does your application need more contributing developers? I build efficient, scalable APIs and turn user interfaces into lightning-fast single-page applications backed by modern tools like GraphQL and traditional RESTful service enpoints.</p>
<p>- <strong>AI Integration:</strong> Is your app having trouble competing in the modern market because it still hasn't properly utilized the services available with AI? As a developer with experience in OpenAI and other AI platforms, I can upgrade your application with GPTs, bots, and other AI-driven functionalities.</p>
<p>- <strong>Code Audits:</strong> Do you need a quality check of your TypeScript, Java, PHP or Python code base? I offer remote and on-site code audits, providing actionable feedback to improve your codebase, enhance performance, and boost security.</p>
<p>If you're interested in collaborating, whether for a one-time project or a full-time position, feel free to reach out via email or LinkedIn. You can also download a copy of my resume directly from the Resume page on this site.</p>
<h4>Tools/Frameworks/Languages:</h4>

<p>-Programming Design Paradigms-<br />  Functional Reactive, Object Oriented, Procedural</p>
<p>-Separation of Concerns-<br /> Restful API, Server Side Rendering, Microservices, Iaas, Static and Dynamic UI, Single Page Apps, Database Integration, Client and Server Authentication</p>
<p>-Languages I am fluent in-<br /> Java, TypeScript/JavaScript, Python, Bash, C, CSS</p>
<p>-Languages I have worked with but not fluent-<br /> PHP, Java, C++, Solidity, Objective C, Perl</p>
<p>-Databases-<br /> MySQL, PostgreSQL, MongoDB, SQLite, GraphQl</p>
<p>-Markup Languages-<br /> HTML, YAML, XML, JSON, Markup</p>
<p>-Version Control-<br /> Git, SVN, Github, GitLab</p>
<p>-Containers and Microservice Infrastructure-<br /> Docker, Kubernetes, Kafka, Terraform</p>
<p>-Operating Systems - Ubuntu, Fedora, MacOS, CentOS, Kali, Debian, Android, Windows</p>
<p>-Libraries-</p>
<p>       JavaScript: React, Vue, Node Standard, Browser API, DOM</p>
<p>       Python: Standard, HTTP, Requests, Pandas, NumPy, PyQt, SpiderPy</p>
<p>       C: GTK, Standard</p>
<p>       Java: Spring, Standard</p>
<p>-Frameworks-</p>
<p>       JavaScript: Angular, Express, Passport, Gatsby, Next, React Native, CSSinJS, Material, Sequelize, and many more</p>
<p>       Python: Django, Flask, Django Rest Framework, Django CMS, Kivy</p>
<p>       PHP: Symfony, Wordpress</p>
<p>       Java: Spring Boot</p>
<p>       CSS: Bootstrap, Less, Sass</p>
<p>-Dev Tools-<br /> OAuth, Vim, Tmux, AWS, Doctl, Netlify, Marbles, Jest, Json Web Tokens, Mocha, Cypress, Travis CI, Nginx, SAML, Apache, Pytest, Emacs, Postman, GPG, and many many more</p>
<h4>Interested?</h4>

<p>If you're interested in collaborating, whether for a one-time project or a full-time position, feel free to reach out via email or LinkedIn. You can also download a copy of my resume directly from the Resume page on this site.</p>
<p>Thank you for taking the time to learn more about me, my background, and what I can offer. I look forward to connecting and exploring how we can work together to create something great.</p>`,
  oldHireMe: `<p>Hello, my name is Corey Waldrop and I am an American Computer enthusiast. In 2008 I joined the U.S. Navy right out of college with the desire to see the world and do something new. I had no idea at the time that not only would I gain a plethora of previously unknown life skills, but I would also develop a passion for computer science, web development and programming. After my service, I started working in the software field with a strong focus on web development. And even though I have had a few entrepreneurial ventures since then, I have always kept my passion for programming and have never stopped learning and keeping up with modern developments in industry tech. And one particular paradigm in the web development field that has brought me the most appeal is that of JavaScript and Node.js. In a way, the recent developments of Node, Angular, React, Restful API development, Vue, GraphQl, Mongodb, etc. has given me an almost renewed passion for the field. So I have been very proud to say that I enjoy what I do for several years now. Included in my business ventures and a handful of companies I have worked for, I have always worked as a freelance Web Developer. Both full and part time, depending on my involvement in other companies. With that being said I would like to point out that I am a new father and husband. So as a personal decision to add some security to my family, I have decided to begin the hunt for a new company to call my home. So until that company is found, I am using this website as a small showcase of my abilities. And when the day comes that I can finally hang my full time freelance hat, I will use this site as a simple personal blog to help out other aspiring developers.</p>
<p>So in a nutshell, being a college educated Navy veteran with almost 10 years of both service and managerial positions in the private sector, I have quite a list of skills and relevant experience. As it pertains to the field of software engineering, I have both the passion and the experience to program virtually anything needed on virtually every platform required. And knowing how constricted the tools/frameworks/devops/languages can be when working in a team environment, I also have the skills necessary to quickly adapt to any paradigm required. Whether it be written in a compiled or scripted language, on the cloud or native, continuously integrated or departmentally revised, or it needs to be designed from ground up or maintained from an existing code base, I have the ability to contribute. I just happen to prefer the modern web development paradigm, both the Restful/GraphQl data side and JavaScript front end side. But that is just the required skills I possess as they pertain to this line of work. I also happen to have a great deal of professional and administrative skills as well. I have started and ran more than one successful business, I have been the CEO of a company and I have been responsible for up to 20 employees working directly for me at a given time. I am verbally affluent, quick thinking, creative and I have proven salesmanship traits. But I am also humble enough to take direction, learn new things and add to all of my personal gloating that this is nothing more than a confident summary of what I have to offer :). So enough of my boasting, the following is a list of the professional topics I offer with a short description of each...</p>
<h4>Developing:</h4>
<p>The first cornerstone is essentially, making new stuff! I have developed software from the initial dry-erase concept, all the way up to deploying and maintaining it. But that also includes everything in between, both creative and menial. Having such a wide range of experience in the process of engineering digital tools has not only taught me what I most enjoy doing, but it has also taught me how to use countless frameworks, programming paradigms and libraries. I will add a list of everything I have worked with to the bottom of this page. But what I do most is CI/CD test driven, object oriented development of both data side Rest APIs written in Java, TypeScript, Python and PHP, and front end UIs written with Angular, React, Gatsby and Wordpress. And naturally, knowing how to use those tools requires the knowledge of many others such as Git, Docker, Linux, GraphQl, Oauth, SQL/NoSQL, Web Tokens, Vim, Bash, etc.</p>
<p>With that being said, over the years doing work for both companies and clients alike, I began to collect a huge list of ideas of my own in regards to software development. I guess most developers are keeping such a list, but there is very little time to tackle such a list when you are spending your computer time meeting deadlines and the such. However, over the last several years I began to slowly but surely contribute some of my ideas to the open source world. I have helped in the development of several programs and software including electron, gnome, and countless other small sites and tools on several platforms to include the web, android, debian, react and linux.</p>
<p>All of the applications/tools I contributed to are both hobby and personal-belief oriented. As I have stated on the About Me section of this website, I love open source and I love the free information nature of the web. Whenever I develop something that belongs to me and me only as a product of doing work for other people, I share it on the open source web. And one of these days, if I am lucky enough to live to an old age and retire, I will spend much more of my time teaching and contributing to the free software world. Until then, I get more than enough fulfillment just working on computers and being and husband/father.</p>
<h4>Consulting:</h4>
<p>My second cornerstone is consulting and freelancing. From the moment I left the Navy and entered the private sector (and for me, the working world as a whole lol) I was doing side work and freelance gigs for people building websites and maintaining systems/servers. I love to work closely with clients on applications and on solving their problems. In this scenario, I am able to shift from being a consultant, who is giving advice and a clear strategy for the application, to being a freelancer who can deep dive into the code and solve problems for people.</p>
<p>So, if you are seeking both an on-site and remote English/Spanish speaking software engineer, don't hesitate to reach out to me. Even if I am fortunate enough to be offered a great position at a great company and no longer have the time to do a lot of freelance work, I will always want to consult when I can. And if you are reading this, then chances are I am still freelancing to an extent anyway lol. So I would love to work with you on your web, mobile, desktop, or embedded applications.</p>
<h4>Your opportunities to work with me:</h4>
<p>As stated before, I am currently looking for a full time web development in order to provide better stability for my family. So even though this list of opportunities would appear to be better suited for a potential freelance develop/consultant gig, it may be of use to a company looking to hire me full time as well...</p>
<p>- Do you have a product idea but can't execute it yourself? I can build an MVP for you from scratch. It would be a vertical cut through all the technologies from full-stack application, connecting it to a database, deploying and hosting it. Along the way, it should solve the most complex problems of your use case. Afterwards, if you wish it, it can go even beyond the initial MVP. It would be your opportunity to have me as a dedicated developer to bootstrap your idea in an effective and efficient way.</p>
<p>- Is your company considering a migration to modern web technologies but no one is there for a proper on-boarding? Over the years I have gathered plenty of experience in the domain of web development, software engineering and JavaScript, especially in JavaScript, and can help your transition. I have helped to modernize, speed up and improve the digital infrastructure for many businesses and companies by upgrading them from an old legacy native system to a modern cloud computed infrastructure. As well as follow all necessary and up to date protocols for the strongest and most reliable security measures available. This may be your opportunity to have me as your helping hand for this endeavor. It is certainly a beneficial one in today's electronically connected and driven world.</p>
<p>- Does your application need more contributing developers? I have collected several years of experience working efficiently in large code bases with a team with a wide variety of different workflows and tools. I would love to be invested in your project, to learn about it and gain a stronger foundation of knowledge from the experience. And by contributing my skills to your project, you would get a reliable developer who is eager to have a positive impact on your tech stack, who is capable of solving complex problems and who is keen to work with your team.</p>
<p>- Do you need a quality check of your TypeScript, Java, PHP or Python code base? I conduct both remote and in house code audits. In today's connected world, there really is no need for me to audit code at a particular location, but I have had some customers in the past that would rather spend the money to have me there in person rather than me working remotely. Either way, most audits can be complete within 1 to 2 business days. All I would need is access to your code base and a briefing in terms of an overview and problems you may have with it. I would then review in the agreed time frame and when complete we would meet by video chat or in person so I can give you valuable suggestions on how to improve the source code. Or if you wish, improve it for you. This sort of consultation is very case by case and usually charged by the hour. Further details regarding this service can be given by reaching out to me.</p>
<p>- Does your company have a great User interface designed and need someone to develop the API necessary for your app to connect to data in an efficient and simple way? I can develop CRUD Restful API services for virtually any kind of requirement for any kind of application. I can also turn your UI into a Single Page Application for lightening fast usage, backed by GraphQl. Or maybe you need help deploying the app partially static partially dynamic to CDNs in order to help you SEO? Chances are, if it can be used by someone over the internet, I can help you build it.</p>
<h4>Tools/Frameworks/Languages:</h4>

<p>-Programming Design Paradigms-<br />  Object Oriented, Procedural</p>
<p>-Separation of Concerns-<br /> Restful API, Server Side Rendering, Static and Dynamic UI, Single Page Apps, Database Integration, Client and Server Authentication</p>
<p>-Languages I am fluent in-<br /> Java, TypeScript/JavaScript, Python, Bash, C, CSS</p>
<p>-Languages I have worked with but not fluent-<br /> PHP, Java, C++, Solidity, Objective C, Perl</p>
<p>-Databases-<br /> MySQL, PostgreSQL, MongoDB, SQLite, GraphQl</p>
<p>-Markup Languages-<br /> HTML, YAML, XML, JSON, Markup</p>
<p>-Version Control-<br /> Git, SVN, Github, GitLab</p>
<p>-Containers-<br /> Docker, Kubernetes</p>
<p>-Operating Systems - Ubuntu, Fedora, MacOS, CentOS, Kali, Debian, Android, Windows</p>
<p>-Libraries-</p>
<p>       JavaScript: React, Vue, Node Standard, Browser API, DOM</p>
<p>       Python: Standard, HTTP, Requests, Pandas, NumPy, PyQt, SpiderPy</p>
<p>       C: GTK, Standard</p>
<p>       Java: Spring, Standard</p>
<p>-Frameworks-</p>
<p>       JavaScript: Angular, Express, Passport, Gatsby, Next, React Native, CSSinJS, Material, Sequelize, and many more</p>
<p>       Python: Django, Flask, Django Rest Framework, Django CMS, Kivy</p>
<p>       PHP: Symfony, Wordpress</p>
<p>       Java: Spring Boot</p>
<p>       CSS: Bootstrap, Less, Sass</p>
<p>-Dev Tools-<br /> OAuth, Vim, Tmux, AWS, Doctl, Netlify, Marbles, Jest, Json Web Tokens, Mocha, Cypress, Travis CI, Nginx, SAML, Apache, Pytest, Emacs, Postman, GPG, and many many more</p>
<h4>Interested?</h4>

<p>If my skills and experience is something of benefit to you and/or your organization, please feel free to contact me via my email or my LinkedIn account. The icons for both links are below. If you are a potential employer looking to get a more professional summary of me, you can access a copy of my resume by either downloading a copy on my LinkedIn account or directly from the link I have posted on the Resume page of this site. One of these days, I may make a Twitter site to help promote my blog if I ever take it to a more serious level, but until then, Twitter is at the very bottom of the list of social networking platforms I consider helpful to the progress of modern society lol.</p>
<p>I would like to thank you for the time to investigate my skills, background and personality for considering my potential for your employ. Whether it be for one project/service or as a full time position at your company, I look forward to hearing from you!</p>`,
}
